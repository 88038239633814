import { appProvider } from '@/app-provider'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { ProfileModel } from '@/models/profile-model'
import { apiService } from '@/services/api-services'
import { walletStore } from '@/stores/wallet-store'
import { action, computed, observable } from 'mobx'
import { asyncAction } from 'mobx-utils'

export class CustomizeProfileViewModel {
  @observable userProfile?: ProfileModel
  @observable mentionUserName = ''
  @observable aboutMe = ''
  @observable updateLoading = false

  constructor() {
    this.fetchUserInfo()
  }

  @asyncAction *fetchUserInfo() {
    try {
      const userId = appProvider.router.currentRoute.params.userId
      const userProfiles = yield apiService.profiles.find({ unique_id: userId, _limit: 1 })
      if (userProfiles[0]) {
        this.userProfile = userProfiles[0]
        this.initValue()
      } else {
        snackController.commonError('Can not find user profile')
      }
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @action.bound initValue() {
    this.mentionUserName = this.userProfile?.mentionUserName ? this.userProfile?.mentionUserName : ''
    this.aboutMe = this.userProfile?.yourselfDescription ? this.userProfile.yourselfDescription : ''
  }

  @action.bound onMentionUserNameChange(input: string) {
    this.mentionUserName = input
  }

  @action.bound onAboutMeChange(input: string) {
    this.aboutMe = input
  }

  @asyncAction *updateCustomizeProfile() {
    try {
      this.updateLoading = true
      const params = {
        id: walletStore.userProfile?._id,
        mentionUserName: this.mentionUserName,
        yourselfDescription: this.aboutMe,
      }
      const res = yield apiService.profiles.updateUserProfile(params)
      if(res) {
        snackController.success('Profile have updated')
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.updateLoading = false
    }
  }

  @computed get aboutMeLength() {
    return this.aboutMe.length
  }

  @computed get overAboutMe() {
    return this.aboutMe.length >= 150
  }
}
