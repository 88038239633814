





















































































































import { localData } from '@/stores/local-data'
import { Observer } from 'mobx-vue'
import { Component, Provide, Vue } from 'vue-property-decorator'
import { connectSocialController } from '../../viewmodels/connect-socical-controller'
import { profileController } from '../../viewmodels/profile-viewmodel'
import { CustomizeProfileViewModel } from '../viewmodels/customize-profile-viewmodel'
@Observer
@Component({
  components: {},
})
export default class CustomizeProfile extends Vue {
  @Provide() vm = new CustomizeProfileViewModel()
  profileController = profileController
  connectSocialController = connectSocialController
  localData = localData

  async updateCustomizeProfile() {
    await this.vm.updateCustomizeProfile()
    await profileController.fetchUserInfo()
  }

  redirectGoogleLogin() {
    localData.setConnectYoutubeFlag(true)
    window.open(this.connectSocialController.getSocialLink('google', true), '_blank')
  }
}
